<template>
  <div>
    <ecat-lightbox-gallery
        :visible="selectedProductImage.visible"
        :images="selectedProductImage.images"
        :current-index="selectedProductImage.currentIndex"
        @hide="hideSelectedProductImage"
    />

    <parser-product-details-modal
        @show-product-images="selectProductImages"
        :shipping-costs="shippingCosts"
        :variations="variations"
        :attributes="attributes"
        ref="parserProductDetailsModal"
    />

    <card>
    <div class="text-center py-4">
      <b-button @click="refreshTableProducts" variant="primary">Wymuś aktualizację tabelki z produktami</b-button>
    </div>

      <div class="button-items">
        <div class="row px-2 mb-3">
          <div class="col-12 col-lg-2">
            <div class="form-group">
              <label>Index hurtowni</label>
              <ecat-input
                  v-model="tableFiltering.warehouseProductId"
                  type="text"
                  @should-reload="refreshTableProducts"
              />
            </div>
          </div>
        </div>
      </div>

    <div class="row mt-3 mb-3">
      <div class="col-sm-12 col-sm-6 d-inline-flex justify-content-end">
        <div class="dataTables_paginate paging_simple_numbers">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"/>
          </ul>
        </div>
        <div class="align-items-center">
          <label class="d-inline-flex align-items-center mx-2 mb-0">
            <b-form-input v-model.number="table.inputPage" type="number" class="form-control form-control-sm ml-2 form-xs"/>
          </label>
          <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, table.inputPage)">{{ $t('table.go-to-page') }}</b-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <b-table
          ref="table"
          responsive="sm"
          :per-page="table.perPage"
          :current-page="table.currentPage"
          :items="getProducts"
          :fields="getProductsTableFields()"
          :empty-text="$t('message.no-elements')"
          :empty-filtered-text="$t('message.no-records')"
          :show-empty="true"
          :busy.sync="table.isBusy">
        <div slot="table-busy" class="text-center">
          <template v-if="table.loaded">
            <h5>Brak produktów!</h5>
          </template>
          <template v-else>
            <h5>{{ $t('message.loading') }}</h5>
            <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
          </template>
        </div>

        <template v-slot:cell(name)="{ item }">
          <template v-if="getProductVariations(item) && getProductVariations(item).length > 0">
            <span>{{ getProductVariations(item)[0].name }}</span>
          </template>
        </template>

        <template v-slot:cell(image)="{ item }">
          <template v-if="getProductVariations(item) && getProductVariations(item).length > 0">
            <template v-if="getProductImages(getProductVariations(item)[0])">
              <img :src="getProductImages(getProductVariations(item)[0])[0]"
                   @click="selectProductImages(getProductImages(getProductVariations(item)[0]))"
                   alt="Product Image" class="rounded avatar-lg">
            </template>
          </template>
        </template>

        <template v-slot:cell(ean)="{ item }">
          <template v-if="item.ean">
            <div :set="ean = jsonUtil.asArray(item.ean)">
              <template v-if="ean.length > 0">
                <span>{{ ean[0] }}</span>
                <span v-if="ean.length > 1" class="badge badge-soft-success font-size-11">+{{
                    ean.length - 1
                  }} {{ $t('message.others') }}</span>
              </template>
              <template v-else>
                <span>-</span>
              </template>
            </div>
          </template>
        </template>

        <template v-slot:cell(expect)="{ item }">
          <span class="badge font-size-11" :class="productHelper.getAllegroSendStatus(item) ? 'badge-soft-success' : 'badge-soft-danger'">
            {{ $t(productHelper.getAllegroSendStatus(item) ? 'message.yes' : 'message.no') }}
          </span>
        </template>

        <template v-slot:cell(tax)="{ item }">
          <span> {{ getTaxRate(item.taxId) }}% </span>
        </template>

        <template v-slot:cell(deliveryTime)="{ item }">
          <span> {{ getProductDeliveryTime(item) }} </span>
        </template>

        <template v-slot:cell(shippingCost)="{ item }">
          <template v-if="getShippingCost(item.id)">
            <p v-for="(shippingCostItem, index) in getShippingCost(item.id)" :key="`shipping-cost-for-product-${index}`">{{shippingCostItem.name }}: {{ priceHelper.format(shippingCostItem.cost, $store.getters['market/currentCurrency']) }}</p>
          </template>
          <template v-else>
            -
          </template>
        </template>

        <template v-slot:cell(action)="{ item }">
          <a :id="`details-${item.id}`" @click="$refs.parserProductDetailsModal.openModal(item)"
             class="clickable-element mr-3 text-primary"><i
              class="mdi mdi-card-account-details font-size-18"></i></a>
          <b-tooltip :target="`details-${item.id}`" placement="left">{{
              $t('products.details.title')
            }}
          </b-tooltip>
        </template>
      </b-table>
    </div>

    <div class="row py-3">
      <div class="col">
        <div class="float-left">
          <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(table), 'elements': paginationHelper.getElementsCount(table), 'all': table.totalRows }) }}</p>
        </div>

        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination v-model="table.currentPage" :per-page="table.perPage" :total-rows="table.rows"/>
          </ul>
        </div>
      </div>
    </div>
    </card>
  </div>
</template>

<script>
import {paginationHelper} from "@/helpers/pagination-helper";
import {toastHelper} from "@/helpers/toast-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import axios from "axios";
import {productsHelper} from "@/helpers/products-helper";
import {priceHelper} from "@/helpers/price-helper";
import {jsonUtil} from "@/helpers/json-util";
import {productHelper} from "@/helpers/product-helper";
import EcatLightboxGallery from "@/components/ecat-lightbox-gallery.vue";
import ParserProductDetailsModal from "@/components/parser/parser-product-details-modal.vue";

export default {
  components: {ParserProductDetailsModal, EcatLightboxGallery},

  computed: {
    priceHelper() {
      return priceHelper
    },

    productHelper() {
      return productHelper
    },

    paginationHelper() {
      return paginationHelper
    },

    jsonUtil() {
      return jsonUtil
    }
  },

  props: {
    warehouseId: {
      type: String,
      required: true
    },

    parsers: {
      validator: function (value) {
        return Array.isArray(value) || value instanceof Object;
      },

      required: true
    },

    profitMargin: {
      type: Number,
      required: true
    },

    filtering: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      taxes: new Map(),
      warehouses: new Map(),
      shippingCosts: new Map(),
      variations: new Map(),
      attributes: new Map(),

      productDataToUpdate: ["images", "name", "ean", "profitMargin", "shopProfit", "priceHigh", "priceLow", "priceWholesaleHigh", "totalWarehouseStock", "warehouse", "tax", "deliveryTime", "industry", "description", "warehouseProfitMargin"],
      productVariationDataToUpdate: [ "images", "name", "ean", "profitMargin", "shopProfit", "priceHigh", "priceLow", "priceMarginTaxIncluded", "price", "priceWholesaleHigh", "totalWarehouseStock", "description", ],

      tasks: [],
      currentParser: 0,
      loadingProductsList: false,
      clearTable: false,
      refreshTable: false,
      
      table: {
        loaded: false,
        perPage: 5,
        currentPage: 1,
        rows: 0,
        totalRows: 0,
        isBusy: false,
        inputPage: "",

        parserProductsMap: new Map(),
        products: []
      },

      tableFiltering: {
        warehouseProductId: ""
      },

      selectedProductImage: {
        visible: false,
        currentIndex: 0,
        images: []
      }
    }
  },
  
  methods: {
    getShippingCost(productId) {
      if (!this.shippingCosts || this.shippingCosts.size === 0) {
        return null;
      }

      return this.shippingCosts.get(productId) || null;
    },

    getProductImages(product) {
      return product.images ? jsonUtil.asArray(product.images) : [];
    },

    selectProductImages(images, currentIndex = 0) {
      this.selectedProductImage.images = images
      this.selectedProductImage.currentIndex = currentIndex
      this.selectedProductImage.visible = true
    },

    hideSelectedProductImage() {
      this.selectedProductImage.images = []
      this.selectedProductImage.currentIndex = 0
      this.selectedProductImage.visible = false
    },

    getProductVariations(product) {
      if (!this.variations) {
        return null
      }

      return this.variations.get(product.warehouseProduct) || null
    },

    async refreshTableProducts() {
      this.loadingProductsList = false
      if (this.refreshTable) {
        return
      }

      this.refreshTable = true
      this.$refs.table.refresh()
    },

    getTaxRate(taxId) {
      if (!this.taxes) {
        return 0;
      }

      const tax = this.taxes.get(taxId);
      if (!tax) {
        return 0;
      }

      return tax.rate;
    },

    async loadDeliveryUnits() {
      try {
        const {data} = await axios.get(`/delivery-unit`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.deliveryUnitsMap = new Map(data.deliveryUnits.map((obj) => [obj.deliveryUnit, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getDeliveryUnit(unit) {
      if (!this.deliveryUnitsMap) {
        return null
      }

      return this.deliveryUnitsMap.get(unit) || null
    },

    getProductDeliveryTime(product) {
      if (product.deliveryTime && product.deliveryUnit) {
        const deliveryUnit = this.getDeliveryUnit(product.deliveryUnit)
        if (deliveryUnit) {
          return product.deliveryTime + " " + deliveryUnit.name
        }
      }

      const warehouse = this.getWarehouse(product.warehouseId)
      if (warehouse) {
        const deliveryUnit = this.getDeliveryUnit(warehouse.deliveryUnit)
        if (deliveryUnit) {
          return warehouse.deliveryTime + " " + deliveryUnit.name
        }
      }

      return this.$t('products.no-delivery-time')
    },

    getProductsTableFields() {
      return [
        {key: "sku", label: 'SKU'},
        {key: "image", label: 'Zdjęcie'},
        {key: "warehouseProduct", label: 'Index Hurtowni'},
        {key: "name", label: 'Nazwa'},
        {
          key: "category", label: this.$t('table.category'), formatter: value => {
            if (!value) {
              return "-"
            }

            return value.name
          }
        },
        {key: "ean", label: 'EAN'},
        {
          key: "profitMargin",
          label: this.$t('table.margin'),
          formatter: (value, key, item) => productsHelper.profitMarginFormatter(value, key, item, this.profitMargin)
        },
        {
          key: "shopProfit",
          label: 'Zysk sklepu',
          formatter: (value, key, item) => productsHelper.profitFormatter(value, key, item, this.getTaxRate(item.taxId), this.profitMargin)
        },
        {
          key: "ecatProfit",
          label: 'Zysk ECAT eCommerce (Full Dropshipping)',
          formatter: (value, key, item) => productsHelper.ecatProfitFormatter(value, key, item, this.getTaxRate(item.taxId), this.profitMargin)
        },
        {
          key: "logistics",
          label: 'Koszty logistyki i księgowości',
          formatter: (value, key, item) => productsHelper.logisticsFormatter(value, key, item, this.getTaxRate(item.taxId), this.profitMargin)
        },
        {
          key: "priceHigh",
          label: this.$t('table.price-high'),
          formatter: (value, key, item) => productsHelper.priceHighFormatter(value, key, item)
        },
        {
          key: "priceLow",
          label: "Cena sklepu netto",
          formatter: (value, key, item) => productsHelper.priceLowFormatter(value, key, item, this.getTaxRate(item.taxId), this.profitMargin)
        },
        {
          key: "priceWholesaleHigh", label: this.$t('table.price-wholesale-high'), formatter: value => {
            if (!value) {
              return ""
            }

            return priceHelper.format(value, this.$store.getters['market/currentCurrency'])
          }
        },
        {key: "expect", label: this.$t('products.table.expect')},
        {key: "totalWarehouseStock", label: this.$t('table.total-warehouse-stock')},
        {
          key: "warehouse", label: this.$t('products.table.warehouse'), formatter: value => {
            if (!value) {
              return "-"
            }

            return value.name
          }
        },
        {key: "tax", label: this.$t('products.table.tax-rate')},
        {key: "deliveryTime", label: this.$t('products.table.delivery-time')},
        {
          key: "industry", label: 'Branża', formatter: value => {
            if (!value) {
              return "-"
            }

            return value.name
          }
        },
        {key: "shippingCost", label: 'Koszt dostawy'},
        {key: "action", label: this.$t("table.actions")}
      ]
    },

    splitProducts() {
      this.table.products = []

      for (const productsList of this.table.parserProductsMap.values()) {
        if (!productsList) {
          continue
        }

        for (const product of productsList) {
          this.addProductOrUpdate(product)
        }
      }
    },

    addProductOrUpdate(product) {
      const alreadyExistsProduct = this.table.products.find(element => element.product.warehouseProduct === product.product.warehouseProduct)
      if (alreadyExistsProduct) {
        this.fillProduct(alreadyExistsProduct.product, product.product)
      } else {
        this.table.products.push(product)
      }
    },

    fillProduct(product, newProduct) {
      for (const data of this.productDataToUpdate) {
        if ((!product[data] || product[data] === "[]" || product[data]) && newProduct[data]) {
          product[data] = newProduct[data]
        }
      }
    },

    fillProductVariation(productVariation, oldProductVariation) {
      for (const data of this.productVariationDataToUpdate) {
        if ((!productVariation[data] || productVariation[data] === "[]") && oldProductVariation[data]) {
          productVariation[data] = oldProductVariation[data]
        }
      }
    },

    splitProductVariations() {
      this.variations = new Map();

      const relation = new Map()

      for (const productsList of this.table.parserProductsMap.values()) {
        if (!productsList) {
          continue;
        }

        for (const product of productsList) {
          relation.set(product.product.id, product.product.warehouseProduct)

          const warehouseProduct = product.product.warehouseProduct;
          if (!this.variations.get(warehouseProduct)) {
            this.variations.set(warehouseProduct, product.productVariations);
          }

          for (const productVariation of product.productVariations) {
            const variation = this.variations.get(warehouseProduct).find(element =>
                element.warehouseVariationId === productVariation.warehouseVariationId
                && relation.get(productVariation.productId) === relation.get(element.productId)
            )

            if (variation) {
              this.fillProductVariation(variation, productVariation)
            }
          }
        }
      }
    },

    splitAttributes() {
      this.attributes = new Map()

      for (const product of this.table.products) {
        for (const key in product.attributesByProductVariationId) {
          // eslint-disable-next-line no-prototype-builtins
          if (product.attributesByProductVariationId.hasOwnProperty(key)) {
            const value = product.attributesByProductVariationId[key];
            if (!value) {
              return;
            }

            let string = ""
            if (typeof value === 'object') {
              for (const prop in value) {
                // eslint-disable-next-line no-prototype-builtins
                if (value.hasOwnProperty(prop)) {
                  string += `${prop}: ${value[prop]} `;
                }
              }
            }

            if (this.attributes.has(key)) {
              this.attributes.get(key).push(string)
            } else {
              this.attributes.set(key, [string])
            }
          }
        }
      }
    },

    async loadWarehouses(products) {
      try {
        if (products.length === 0) {
          return;
        }

        const ids = products.map(product => product.warehouseId);
        const json = {
          ids: ids
        }

        const {data} = await axios.post(`/warehouse/fetch`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.warehouses = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getWarehouse(id) {
      return this.warehouses ? this.warehouses.get(id) || null : null;
    },

    async loadTaxes(products) {
      try {
        if (products.length === 0) {
          return;
        }

        const ids = products.map(product => product.taxId);
        const json = {
          ids: ids
        }

        const {data} = await axios.post(`/tax/list-by-ids`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.taxes = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadShippingCosts(products) {
      try {
        if (products.length === 0) {
          return;
        }

        const json ={
          products: products
        }

        const {data} = await axios.post(`/product/target-shipping-costs/by-products`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        if (data && data.data) {
          this.shippingCosts = new Map()

          data.data.forEach(item => {
            const productId = item.productId;
            const carrierData = {
              carrierId: item.carrierId,
              allegroCarrierId: item.allegroCarrierId,
              cost: item.cost,
              name: item.name
            };

            if (this.shippingCosts.has(productId)) {
              this.shippingCosts.get(productId).push(carrierData);
            } else {
              this.shippingCosts.set(productId, [carrierData]);
            }
          });
        }
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async startProductsTask(xmlUrl, tagSetup) {
      const productValues = Array.from(this.table.parserProductsMap.values());
      const warehouseProductIds = this.tableFiltering.warehouseProductId
          ? [this.tableFiltering.warehouseProductId]
          : productValues.flatMap(productsList => {
            return productsList.map(product => product.product.warehouseProduct);
          });

      try {
        const json = JSON.stringify({
          xmlUrl: xmlUrl,
          tagSetup: JSON.stringify(tagSetup),
          warehouseId: this.warehouseId,
          page: this.table.currentPage,
          pageSize: this.table.perPage,
          warehouseProductIds: warehouseProductIds
        })

        const { data } = await axios.post(`/parser/tag/list-products`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        return data.taskId
      } catch (error) {
        return null
      }
    },

    async loadProducts(xmlUrl, intervalId, taskId) {
      try {
        const { data } = await axios.get(`/task/${taskId}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        const { status } = data
        if (status === "COMPLETED") {
          if (data.response.count < this.table.rows) {
            this.table.rows = data.response.count
          } else if (this.table.rows === 0) {
            this.table.rows = data.response.count
          }

          if (data.response.count < this.table.totalRows) {
            this.table.totalRows = data.response.count
          } else if (this.table.totalRows === 0) {
            this.table.totalRows = data.response.count
          }

          this.table.parserProductsMap.set(xmlUrl, data.response.products)

          if (this.filtering.mergeProductsTable) {
            await this.createNextParserTask()
          }

          const task = this.tasks.find(element => element.taskId === taskId)
          if (task) {
            task.completed = true
          }

          clearInterval(intervalId)
          return null
        } else if (status === "FAILED") {
          await this.$bvToast.toast(data.response.message, {
            title: this.$t('message.error'),
            variant: 'danger',
            solid: true
          });

          const task = this.tasks.find(element => element.taskId === taskId)
          if (task) {
            task.completed = true
          }

          clearInterval(intervalId)
          return null
        } else if (status !== "PROCESSING") {
          const task = this.tasks.find(element => element.taskId === taskId)
          if (task) {
            task.data = null
            task.error = true
          }

          clearInterval(intervalId)
          return null
        }
      } catch (error) {
        const task = this.tasks.find(element => element.taskId === taskId)
        if (task) {
          task.completed = true
        }

        clearInterval(intervalId)

        if (error.response && error.response.data && error.response.data.status === "FAILED" && error.response.data.response.message) {
          toastHelper.error(error.response.data.response.message)
        } else if (error.response && error.response.data && error.response.data.status === "FAILED" && error.response.data.response.error && error.response.data.response.error.message) {
          toastHelper.error(error.response.data.response.error.message)
        } else {
          errorCatcher.catchErrors(error)
        }
      }

      return null
    },

    async createNextParserTask() {
      if (this.currentParser < this.parsers.length) {
        const targetParser = this.parsers[this.currentParser];
        const xmlUrl = targetParser.parser.xmlUrl
        if (!xmlUrl) {
          return
        }

        const tagSetup = targetParser.tagSetup

        const taskId = await this.startProductsTask(xmlUrl, tagSetup)
        const intervalId = setInterval(async () => {
          await this.loadProducts(xmlUrl, intervalId, taskId);
        }, 4500)

        this.tasks.push({
          intervalId: intervalId,
          taskId: taskId,
          data: null,
          completed: false,
          error: false,
        })

        this.currentParser++;
      }
    },

    async getProducts() {
      if (this.loadingProductsList) {
        return []
      }

      this.table.parserProductsMap = new Map()

      this.table.products = []
      this.table.rows = 0
      this.table.totalRows = 0
      this.table.loaded = false

      if (this.clearTable && !this.refreshTable) {
        this.clearTable = false
        return []
      }

      this.clearTable = false
      this.refreshTable = false
      this.loadingProductsList = true

      for (const task of this.tasks) {
        clearInterval(task.intervalId)
      }

      this.tasks = []

      this.currentParser = 0;
      await this.createNextParserTask()

      return new Promise((resolve) => {
        const checkCompletion = async () => {
          const completedTasks = this.tasks.every(task => task.completed);
          if (completedTasks) {
            clearInterval(interval); // Clear the interval
            this.splitProducts();
            this.splitProductVariations();
            this.splitAttributes();

            const products = this.table.products.map(product => product.product);

            try {
              await this.loadTaxes(products);
              await this.loadWarehouses(products);
              await this.loadShippingCosts(products);
            } catch (error) {
              // ignored
            }

            this.loadingProductsList = false;
            this.table.loaded = true
            resolve(products);
          }

          if (this.tasks.some(task => task.error)) {
            clearInterval(interval);
            this.table.loaded = false
            this.loadingProductsList = false;
            resolve([])
          }
        };

        const interval = setInterval(checkCompletion, 1500);
      });
    },
  },

  mounted() {
    this.$root.$on("ecat-parser-refresh-table", (xmlUrl) => {
      if (!this.filtering.mergeProductsTable && xmlUrl && this.parsers[0].parser.xmlUrl !== xmlUrl) {
        return
      }

      if (!this.filtering.automaticallyUpdateProductTable) {
        return
      }

      if (this.loadingProductsList) {
        return
      }

      if (this.refreshTable) {
        return
      }

      this.refreshTable = true
      this.$refs.table.refresh()
    });

    this.$root.$on("ecat-parser-clear-table", () => {
      if (this.clearTable) {
        return
      }

      this.clearTable = true
      this.$refs.table.refresh()
    });
  },

  beforeDestroy() {
    this.$root.$off("ecat-parser-refresh-table");
    this.$root.$off("ecat-parser-clear-table")
  }
  
}
</script>