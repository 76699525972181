<template>
    <rectangle :matched="!!matchedTag()" :key="`parser-attribute-column-${reloadColumn}`">
      <template #top-left-buttons>
        <b-button v-if="!!matchingTag && visibleMatchButton" @click="$emit('match-tag', attribute, true)" variant="success" class="btn-sm"><i class="mdi mdi-plus" /></b-button>
      </template>

      <template #centered>
        <h1 class="tag-name-clamp">{{ attribute.name }}</h1>
        <h1 v-if="!!matchedTag()" class="tag-name-clamp">(<b>{{ matchedTagName() }}</b>)</h1>
      </template>

      <template #buttons>
        <b-button v-if="!!matchedTag()" @click="$root.$emit('open-parser-tag-preview-modal', matchedLeftTag(matchedTag()))" variant="primary" class="btn-sm">Podgląd</b-button>
        <b-button variant="danger" class="btn-sm" @click="removeAttribute">Usuń</b-button>
        <b-button v-if="!!matchedTag()" @click="$root.$emit('open-parser-reject-match-modal', parserObject.parser.xmlUrl, tagSetup, matchedLeftTag(matchedTag()), true)" variant="danger" class="btn-sm">Odrzuć dopasowania</b-button>
      </template>
    </rectangle>
</template>

<script>
import Rectangle from "@/components/rectangle.vue";
import {swalHelper} from "@/helpers/swal-helper";

export default {
  components: {Rectangle},
  props: {
    attribute: {
      type: Object,
      required: true
    },

    matchingTag: {},

    parserObject: {
      type: Object,
      required: true
    },
    
    tagSetup: {
      type: Object,
      required: true
    },

    visibleMatchButton: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      reloadColumn: false
    }
  },

  methods: {
    matchedTag() {
      return this.tagSetup.matchedTags.find(item => item.name === this.attribute.name);
    },

    matchedLeftTag(matchedTag) {
      return matchedTag ? this.parserObject.leftTags.find(item => item.path === matchedTag.tagPath) : null;
    },

    matchedTagName() {
      const matchedTag = this.matchedTag()
      const matchedLeftTag = this.matchedLeftTag(matchedTag)
      if (!matchedLeftTag) {
        return null
      }

      return matchedTag.targetAttributeName
          ? `${matchedLeftTag.tagName}/${matchedTag.targetAttributeName}`
          : matchedLeftTag.tagName
    },

    removeAttribute() {
      swalHelper.yesOrNo(() => {
        const lengthBeforeRemove = this.tagSetup.matchedTags.length

        this.tagSetup.frontendAttributes = this.tagSetup.frontendAttributes.filter(element => element.name !== this.attribute.name)
        this.tagSetup.matchedTags = this.tagSetup.matchedTags.filter(element => element.name !== this.attribute.name);

        if (lengthBeforeRemove !== this.tagSetup.matchedTags.length) { // NOTE: optimization
          const { parser } = this.parserObject
          this.$root.$emit("ecat-parser-refresh-table", parser.xmlUrl)
        }
      });
    }
  },

  mounted() {
    this.$root.$on("reload-parser-columns", () => {
      this.reloadColumn = !this.reloadColumn
    })
  },

  beforeDestroy() {
    this.$root.$off("reload-parser-columns");
  }

}
</script>