<template>
  <b-modal
      v-if="parserObject && tagSetup()"
      v-model="visibleModal"
      centered
      hide-footer
      size="xl"
      title="Wybieranie atrybutu do relacji"
      title-class="font-18"
      @esc="hideModal"
      @hide="hideModal">
    <div class="form-group">
      <div class="row">
        <div class="col-12">

          <card>
            <div class="parser-rectangle-table">
              <div class="parser-rectangle-content">
                <div v-for="(item, index) in attributes" :key="index">
                  <parser-relation-attribute-column
                      :attribute-name="item"
                      :parser-object="parserObject"
                      @select-attribute="attributeName => selectRelationAttribute(attributeName)"
                  />
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import parserRelationAttributeColumn from "@/components/parser/parser-relation-attribute-column.vue";

export default {

  components: {
    parserRelationAttributeColumn
  },

  props: {
    parserObject: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      visibleModal: false,
      attributes: [],
      selectedAttribute: ""
    }
  },

  methods: {
    tagSetup() {
      return this.parserObject.tagSetup
    },
    
    openModal(attributes) {
      this.visibleModal = true

      this.attributes = []
      this.selectedAttribute = ""

      for (const key in attributes) {
        // eslint-disable-next-line no-prototype-builtins
        if (attributes.hasOwnProperty(key)) {
          this.attributes.push(key)
        }
      }
    },

    hideModal() {
      this.visibleModal = false
      this.$emit("clear-who-tag")
    },

    selectRelationAttribute(attributeName) {
      this.$emit("set-attribute", attributeName)
      this.hideModal()

      this.attributes = []
      this.selectedAttribute = ""
    }
  }

}
</script>