<template>
  <div class="form-group text-center">
    <div class="text-center py-3" v-if="loading">
      <h5>{{ $t('message.loading') }}</h5>
      <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
    </div>
    <div v-else>
    <div class="parser-rectangle-table">
      <div class="xml-rectangle-content">
        <ecat-code-highlight :index="index" language="xml" :code="getText()" :key="getText()" ref="codeHighlight" />
      </div>
    </div>
    </div>

    <div class="button-items py-2" v-if="showButton">
      <b-button variant="primary" @click="showMore">Załaduj więcej linijek</b-button>
      <b-button variant="warning" @click="showMoreText">Pokaż więcej tekstu</b-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import EcatCodeHighlight from "@/components/parser/ecat-code-highlight.vue";
import Swal from "sweetalert2";

export default {
  name: "preview-xml",
  components: {
    EcatCodeHighlight
  },

  props: {
    index: {
      type: Number,
      default: 0
    },

    xmlUrl: {
      type: String,
      required: true
    },

    tagSetup: {
      type: Object,
      required: true
    },

    showButton: {
      type: Boolean,
      default: true
    },

    highlightedTag: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      loading: false,

      xmlCode: "",
      lineCount: 1000,
      textCount: 3000,

      task: {
        id: "",
        intervalId: 0
      }
    }
  },

  methods: {
    getText() {
      return this.xmlCode.substring(0, this.textCount)
    },

    async showMore() {
      this.lineCount += 1000
      await this.loadPreviewXml()
    },

    showMoreText() {
      if (this.textCount > this.xmlCode.length) {
        Swal.fire("", "Brak tekstu do wyświetlenia. Załaduj więcej linijek!", "error");
        return
      }

      this.textCount += 3000
      this.highlightTag()
    },

    async loadPreviewXml() {
      const json = JSON.stringify({
        xmlUrl: this.xmlUrl,
        tagSetup: JSON.stringify(this.tagSetup),
        lineCount: this.lineCount
      });

      try {
        const {data} = await axios.post(`/parser/tag/preview`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
        })

        const { taskId }  = data
        this.task.id = taskId
        this.enableCheckTask()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async highlightTag() {
      if (!this.highlightedTag) {
        return
      }

      // eslint-disable-next-line no-unused-vars
      for (const index of [1, 2]) {
        await this.$refs.codeHighlight.highlightText(this.highlightedTag)
      }
    },

    enableCheckTask() {
      this.disableCheckTask()

      const that = this
      this.task.intervalId = setInterval(async () => {
        await that.checkTask()
      }, 2500);
    },

    disableCheckTask() {
      if (!this.task.intervalId) {
        return
      }

      clearInterval(this.task.intervalId)
      this.task.intervalId = 0
    },

    async checkTask() {
      try {
        const { data } = await axios.get(`/task/${this.task.id}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        const { status } = data
        if (status === "COMPLETED") {
          this.disableCheckTask()
          this.xmlCode = data.response
          await this.highlightTag()
        } else if (status === "FAILED") {
          this.disableCheckTask()
          await this.$bvToast.toast(data.response.message, {
            title: this.$t('message.error'),
            variant: 'danger',
            solid: true
          });
        } else if (status !== "PROCESSING") {
          this.disableCheckTask()
        }

        this.loading = false
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }
  },

  async created() {
    this.loading = true
    await this.loadPreviewXml()
  }

}
</script>

<style scoped>
.xml-rectangle-content {
  display: flex;
  height: 100%;
}
</style>