<template>
  <b-modal
      v-if="tagSetup"
      v-model="visibleModal"
      title="Zmień typ ceny"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">

    <div>
      <input type="radio" id="NET" value="NET" v-model="tagSetup.priceType" @change="canReload = true" name="priceType">
      <label for="NET" style="margin-left: 5px;">Cena netto</label>
    </div>

    <div>
      <input type="radio" id="GROSS" value="GROSS" v-model="tagSetup.priceType" @change="canReload = true" name="priceType">
      <label for="GROSS" style="margin-left: 5px;">Cena brutto</label>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      visibleModal: false,
      canReload: false,

      xmlUrl: null,
      tagSetup: null,
    }
  },

  methods: {
    openModal(xmlUrl, tagSetup) {
      this.xmlUrl = xmlUrl
      this.tagSetup = tagSetup
      this.visibleModal = true
    },

    async hideModal() {
      if (this.canReload) {
        this.canReload = false
        
        await this.$root.$emit("ecat-parser-refresh-table", this.xmlUrl)
      }

      this.visibleModal = false
      this.xmlUrl = null
    }
  }
}
</script>