<template>
  <b-modal
      size="xl"
      v-model="visibleModal"
      v-if="tag"
      :scrollable="true"
      :title="`Podgląd: ${tag.path}`"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <div class="form-group text-center">
      <h5>Przykładowe wartości:</h5>

      <div v-for="(index) in showCount" :key="index">
        <div v-if="tag.values[index - 1]" v-html="`<b>${index}.</b> ${textValue(index - 1)}`" />

        <div class="button-items py-2">
          <b-button v-if="tag.values[index - 1]" variant="warning" class="btn-sm" @click="showMoreText(index - 1)" :disabled="!canShowMoreText(index - 1)">Pokaż więcej tekstu</b-button>
        </div>
      </div>

      <div class="button-items py-2">
        <b-button variant="primary" v-if="tag.values.length > showCount" @click="showMore">Załaduj więcej</b-button>
      </div>
    </div>

<!--    <preview-xml :xml-url="xmlUrl" :tag-setup="tagSetup" />-->
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      tag: null,

      visibleModal: false,
      showCount: 5,
      valuesLengthMap: new Map()
    }
  },

  computed: {
    textValue() {
      return (value) => {
        return this.tag.values[value].substring(0, this.getValueLength(value))
      }
    }
  },

  methods: {
    openModal(tag) {
      if (!tag.values || !tag.values.length) {
        Swal.fire("Błąd!", "Brak podglądu dla tego TAGA!", "error")
        return
      }

      this.tag = tag
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.tag = null
    },

    getValueLength(value) {
      if (!this.valuesLengthMap.has(value)) {
        this.valuesLengthMap.set(value, 100);
        return 100
      }

      return this.valuesLengthMap.get(value);
    },

    canShowMoreText(valueIndex) {
      const currentTextLength = this.getValueLength(valueIndex)
      const value = this.tag.values[valueIndex]
      if (currentTextLength >= value.length) {
        return false
      }

      return true
    },

    showMoreText(valueIndex) {
      this.valuesLengthMap.set(valueIndex, this.valuesLengthMap.get(valueIndex) + 100)
      this.$forceUpdate()
    },

    showMore() {
      const { length } = this.tag.values
      if (this.showCount >= length) {
        return
      }

      this.showCount += 5
      if (this.showCount >= length) {
        this.showCount = length
      }
    }
  }

}
</script>