<template>
  <b-modal
      v-if="tagSetup && tagSetup.quantityMappings"
      v-model="visibleModal"
      size="lg"
      title="Mapowanie ilości produktów"
      title-class="font-18"
      hide-footer
      centered
      @hide="hideModal"
      @esc="hideModal">

    <div class="row mb-2 text-center">
      <div class="col-12 col-lg-2" />

      <div class="col-12 col-lg-4">
        <h4>Klucz</h4>
      </div>

      <div class="col-12 col-lg-4">
        <h4>Wartość</h4>
      </div>

      <div class="col-12 col-lg-2" />
    </div>

    <div class="row mb-2" v-for="(item, index) in tagSetup.quantityMappings.frontendMappings" :key="index">
      <div class="col-12 col-lg-2" />

      <div class="col-12 col-lg-4">
        <input v-model="item.key" type="text" class="form-control" />
      </div>

      <div class="col-12 col-lg-4">
        <input v-model="item.value" type="number" class="form-control" />
      </div>

      <div class="col-12 col-lg-2">
        <b-button @click="remove(item)" variant="danger"><i class="fa fa-times"/></b-button>
      </div>
    </div>

    <div class="form-group text-center py-2">
      <b-button @click="add" variant="success"><i class="fa fa-plus" /></b-button>
    </div>
  </b-modal>
</template>

<script>
export default {

  data() {
    return {
      visibleModal: false,
      tagSetup: null
    }
  },

  methods: {
    openModal(tagSetup) {
      this.tagSetup = tagSetup
      this.tagSetup.quantityMappings.frontendMappings = []
      for (const key in this.tagSetup.quantityMappings.mappings) {
        const value = this.tagSetup.quantityMappings.mappings[key];
        this.tagSetup.quantityMappings.frontendMappings.push({
          key: key,
          value: value
        })
      }

      this.visibleModal = true
    },

    hideModal() {
      this.tagSetup.quantityMappings.mappings = {}
      for (const mapping of this.tagSetup.quantityMappings.frontendMappings) {
        this.tagSetup.quantityMappings.mappings[mapping.key] = mapping.value
      }

      this.visibleModal = false
    },

    add() {
      this.tagSetup.quantityMappings.frontendMappings.push({
        key: "",
        value: ""
      })
    },

    remove(mapping) {
      this.tagSetup.quantityMappings.frontendMappings = this.tagSetup.quantityMappings.frontendMappings.filter(element => element !== mapping)
    }
  }

}
</script>