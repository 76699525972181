<template>
  <div>
    <parser-match-attributes-modal
        :parser-object="parserObject"
        :tag-setup="tagSetup"
        :required-tags="requiredTags"
        :matching-tag="matchingTag"
        @match-right-tag="matchAttributesRightTag"
        ref="parserMatchAttributesModal"
    />

    <parser-accept-attributes-modal
        :parser-object="parserObject"
        :tag-setup="tagSetup"
        @reload-column="reloadColumn = !reloadColumn"
        ref="parserAcceptAttributesModal"
    />

    <div v-if="parserObject.loaded" class="row d-flex justify-content-center py-4">
      <div class="col-12 col-lg-4">
        <card>
          <div class="table-responsive">
            <table class="table table-hover table-bordered text-center">
              <tbody>
              <tr>
                <td>Ilość wszystkich tagów: <b>{{ parserObject.leftTags.length }}</b></td>
              </tr>
              <tr>
                <td>Ilość tagów do dopasowania: <b>{{ requiredTagsCount() }}</b></td>
              </tr>
              <tr>
                <td>Ilość tagów dopasowanych: <b>{{ tagSetup.matchedTags.length }}</b></td>
              </tr>
              <tr>
                <td>Ilość tagów odrzuconych: <b>{{ tagSetup.rejectedTags.length }}</b></td>
              </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>

      <div class="col-12 mb-4" v-if="parserIndex > 0">
        <div class="button-items text-center">
          <b-button @click="matchTagsFromFirstXML" variant="danger">Dopasuj powiązania tagów XML z pierwszej sekcji</b-button>
        </div>
      </div>

      <div class="col-12">
        <div class="button-items text-center">
          <b-button variant="primary" @click="$emit('open-parser-quantity-mappings-modal', tagSetup)">Mapowanie
            ilości produktów
          </b-button>
          <b-button variant="primary" @click="$emit('open-parser-change-price-type-modal', parserObject.parser.xmlUrl, tagSetup)">Zmień typ
            ceny
          </b-button>

          <b-button variant="primary" @click="$emit('open-parser-images-text-separator-modal', parserObject.parser.xmlUrl, tagSetup)">Tekst
            oddzielający obrazki
          </b-button>
          <b-button variant="primary" @click="$emit('open-parser-image-url-prefix-modal', parserObject.parser.xmlUrl, tagSetup)">Prefiks adresu
            URL obrazu
          </b-button>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-center">
      <template v-if="!parserObject.parser.xmlUrl">
        <parser-card-info>
          <span aria-hidden="true" class="m-2 fa fa-times text-danger font-size-24"/>
          <h5>Wprowadź adres URL do pliku XML!</h5>
        </parser-card-info>
      </template>
      <template v-else-if="!parserObject.loaded && !parserObject.loading">
        <parser-card-info>
          <span aria-hidden="true" class="m-2 mdi mdi-information text-primary font-size-24"/>
          <h5>Przetwórz dane, aby załadować ten Parser</h5>
        </parser-card-info>
      </template>
      <template v-else-if="!parserObject.loaded && !parserObject.errorMessage">
        <parser-card-info>
          <h5>{{ $t('message.loading') }}</h5>
          <span aria-hidden="true" class="m-2 spinner-border text-primary"/>
        </parser-card-info>
      </template>
      <template v-else-if="parserObject.errorMessage">
        <parser-card-info>
          <span aria-hidden="true" class="m-2 fa fa-times text-danger font-size-24"/>
          <h5>Podczas ładowania parsera wystąpił błąd:</h5>
          <h5 class="text-danger">{{ parserObject.errorMessage }}</h5>
        </parser-card-info>
      </template>

      <template v-else>
        <div class="col-12 col-lg-5">

          <h2 class="text-center">Tagi XML</h2>
          <input v-model="filtering.leftTagName" class="form-control" placeholder="Wyszukaj po nazwie TAGA"
                 type="text"/>

          <div class="card">
            <div class="card-body">
              <div class="parser-rectangle-table">
                <div class="parser-rectangle-content">
                  <parser-left-column
                      v-for="(tag, index) in filteredLeftTags()"
                      :key="`parser-left-tag-${index}-${reloadColumn}`"
                      :parser-object="parserObject"
                      :tag-setup="tagSetup"
                      :tag="tag"
                      @highlight-tag="highlightTag"
                      @match-tag="matchLeftTag"
                      @reject-tag="rejectTag"
                      @restore-tag="restoreTag"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-5">
          <h2 class="text-center">Tagi ECAT</h2>

          <input v-model="filtering.rightTagName" class="form-control" placeholder="Wyszukaj po nazwie TAGA"
                 type="text"/>

          <card>
            <div class="parser-rectangle-table">
              <div class="parser-rectangle-content">
                <parser-right-column
                    v-for="(tag, index) in filteredRightTags()"
                    :key="`parser-right-tag-${index}-${reloadColumn}`"
                    :parser-object="parserObject"
                    :tag-setup="tagSetup"
                    :required-tags="requiredTags"
                    :matching-tag="matchingTag"
                    :tag="tag"
                    @match-tag="matchRightTag"
                />

                <hr/>
                <h2>Atrybuty:</h2>

                <parser-attribute
                    v-for="(attribute, index) in tagSetup.frontendAttributes"
                    :key="`parser-attribute-${index}-${reloadColumn}`"
                    :parser-object="parserObject"
                    :tag-setup="tagSetup"
                    :matching-tag="matchingTag"
                    :attribute="attribute"
                    @match-tag="matchRightTag"
                />

                <div class="button-items py-4 text-center">
                <!--                    <b-button variant="success" class="btn-lg" @click="openAddNewRightTagModal">Dodaj nowy TAG</b-button>-->
                  <b-button variant="info" class="btn-lg" @click="$root.$emit('open-parser-add-attribute-modal', parserObject)">Dodaj nowy Atrybut</b-button>
                </div>
              </div>
            </div>
          </card>
        </div>
      </template>
    </div>

    <div v-if="parserObject.loaded" class="row d-flex justify-content-center py-4">
      <div class="col-12 col-lg-8">
        <h2 class="text-center">Podgląd pliku XML</h2>

        <card>
          <preview-xml
              ref="previewXmlRef"
              :highlighted-tag="highlightedTag"
              :show-button="false"
              :tag-setup="tagSetup"
              :xml-url="parserObject.parser.xmlUrl"
          />

          <div class="button-items text-center py-2">
            <b-button variant="primary" @click="$refs.previewXmlRef.showMore()">Załaduj więcej linijek</b-button>
            <b-button variant="warning" @click="$refs.previewXmlRef.showMoreText()">Pokaż więcej tekstu</b-button>
          </div>
        </card>
      </div>
    </div>


    <parser-products-table v-if="!tableFiltering.mergeProductsTable" :filtering="tableFiltering" :warehouse-id="warehouseId" :profit-margin="profitMargin" :parsers="[parserObject]" />
  </div>
</template>

<script>
import ParserLeftColumn from "@/components/parser/parser-left-column.vue";
import ParserRightColumn from "@/components/parser/parser-right-column.vue";
import {translateRightTagName} from "@/helpers/parser-util"
import PreviewXml from "@/components/parser/preview-xml.vue";
import ParserAttribute from "@/components/parser/parser-attribute.vue";
import Swal from "sweetalert2";
import ParserMatchAttributesModal from "@/components/parser/parser-match-attributes-modal.vue";
import ParserAcceptAttributesModal from "@/components/parser/parser-accept-attributes-modal.vue";
import ParserProductsTable from "@/components/parser/parser-products-table.vue";
import ParserCardInfo from "@/components/parser/parser-card-info.vue";

export default {
  components: {
    ParserCardInfo,
    ParserProductsTable,
    ParserAcceptAttributesModal,
    ParserMatchAttributesModal, ParserAttribute, PreviewXml, ParserRightColumn, ParserLeftColumn
  },

  props: {
    parserIndex: {
      type: Number,
      required: true
    },

    firstParserObject: {
      type: Object,
      required: true
    },

    parserObject: {
      type: Object,
      required: true
    },

    tagSetup: {
      type: Object,
      required: true
    },

    requiredTags: {
      type: Array,
      required: true
    },

    warehouseId: {
      type: String,
      required: true
    },

    profitMargin: {
      type: Number,
      required: true
    },

    tableFiltering: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      reloadColumn: false,
      highlightedTag: "",
      matchingTag: null,

      filtering: {
        leftTagName: "",
        rightTagName: ""
      }
    }
  },

  methods: {
    matchTagsFromFirstXML() {
      if (!this.firstParserObject) {
        Swal.fire("", "Pierwszy parser nie znaleziony!", "error")
        return
      }

      const currentTagSetup = this.tagSetup
      const firstTagSetup = this.firstParserObject.tagSetup

      let count = 0
      for (const matchedTag of firstTagSetup.matchedTags) {
        if (matchedTag.attribute || matchedTag.customTag || !this.parserObject.leftTags.some(item => item.path === matchedTag.tagPath)) {
          continue
        }

        if (!currentTagSetup.matchedTags.some(item => item.path !== matchedTag.tagPath)) {
          this.addMatchTag(matchedTag.name, matchedTag.tagPath, false)
          ++count
        }
      }

      const { parser } = this.parserObject

      if (count > 0) {
        Swal.fire("Sukces!", `Dopasowano: ${count} TAGÓW!`, "success")
            .then(() => this.$root.$emit("ecat-parser-refresh-table", parser.xmlUrl))
        return
      }

      Swal.fire("Błąd!", `Nie znaleziono żadnych podobnych dopasowań lub wszystkie tagi zostały już wcześniej dopasowane!`, "error")
    },

    filteredLeftTags() {
      return this.filtering.leftTagName
          ? this.parserObject.leftTags.filter(element => element.tagName.includes(this.filtering.leftTagName))
          : this.parserObject.leftTags;
    },

    filteredRightTags() {
      return this.filtering.rightTagName
          ? this.parserObject.rightTags.filter(element => translateRightTagName(element.name).toLowerCase().includes(this.filtering.rightTagName.toLowerCase()))
          : this.parserObject.rightTags;
    },

    rejectTag(tag) {
      const lengthBeforeRemove = this.tagSetup.matchedTags.length

      this.tagSetup.rejectedTags.push(tag.path)
      this.tagSetup.matchedTags = this.tagSetup.matchedTags.filter(element => element.tagPath !== tag.path);

      if (lengthBeforeRemove !== this.tagSetup.matchedTags.length) { // NOTE: optimization
        const { parser } = this.parserObject
        this.$root.$emit("ecat-parser-refresh-table", parser.xmlUrl)
      }

      this.reloadColumn = !this.reloadColumn
      this.$root.$emit("reload-parser-columns")
    },

    restoreTag(tag) {
      const index = this.tagSetup.rejectedTags.indexOf(tag.path);
      if (index > -1) {
        this.tagSetup.rejectedTags.splice(index, 1);
        this.reloadColumn = !this.reloadColumn
        this.$root.$emit("reload-parser-columns")
      }
    },

    requiredTagsCount() {
      const matchedRequiredTags = this.tagSetup.matchedTags.filter(matchedTag => this.requiredTags.includes(matchedTag.name));
      const missingRequiredCount = this.requiredTags.length - matchedRequiredTags.length;

      return Math.max(missingRequiredCount, 0);
    },

    async highlightTag(tagName) {
      this.highlightedTag = tagName
      await this.$refs.previewXmlRef.highlightTag()
    },

    matchLeftTag(tag, attributes) {
      this.matchingTag = tag
      if (attributes) {
        this.$refs.parserMatchAttributesModal.openModal(tag)
      }
    },

    matchRightTag(tag, attribute = false) {
      if (!this.matchingTag) {
        Swal.fire("Błąd!", "Matching TAG is not set!", "error");
        return
      }

      if (this.matchingTag.attributes && Object.keys(this.matchingTag.attributes).length > 0) {
        this.$refs.parserAcceptAttributesModal.openModal(attribute, this.matchingTag, tag)
      } else {
        this.addMatchTag(tag.name, this.matchingTag.path, attribute)

        const { parser } = this.parserObject
        this.$root.$emit("ecat-parser-refresh-table", parser.xmlUrl)
        this.reloadColumn = !this.reloadColumn
        this.$root.$emit("reload-parser-columns")
      }

      this.matchingTag = null
    },

    matchAttributesRightTag(selectedAttribute, tag, attribute = false) {
      if (!this.matchingTag) {
        Swal.fire("Błąd!", "Matching TAG is not set!", "error");
        return
      }

      if (this.matchingTag.attributes && Object.keys(this.matchingTag.attributes).length > 0) {
        this.$refs.parserAcceptAttributesModal.openModal(attribute, this.matchingTag, tag, selectedAttribute)
      } else {
        this.addMatchTag(tag.name, this.matchingTag.path, attribute, selectedAttribute)

        const { parser } = this.parserObject
        this.$root.$emit("ecat-parser-refresh-table", parser.xmlUrl)
        this.reloadColumn = !this.reloadColumn
        this.$root.$emit("reload-parser-columns")
      }

      this.matchingTag = null
    },

    addMatchTag(name, tagPath, attribute, targetAttributeName = null) {
      const matchedTag = {
        name: name,
        tagPath: tagPath,
        attribute: attribute,
        customTag: false
      }

      if (targetAttributeName) {
        matchedTag.targetAttributeName = targetAttributeName
      }

      this.tagSetup.matchedTags.push(matchedTag)
      this.reloadColumn = !this.reloadColumn
      this.$root.$emit("reload-parser-columns")
    }
  },

  mounted() {
    this.$root.$on("reload-parser-column", (xmlUrl) => {
      if (this.parserObject.parser.xmlUrl !== xmlUrl) {
        return
      }

      this.reloadColumn = !this.reloadColumn
    })
  },

  beforeDestroy() {
    this.$root.$off("reload-parser-column");
  }

}
</script>

<style>
.tag-name-clamp {
  max-width: 100%;
  text-align: center;
  overflow: hidden;
  font-size: clamp(1rem, 1vw, 2rem);
}
</style>