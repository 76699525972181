<template>
  <b-modal
      v-if="tagSetup"
      v-model="visibleModal"
      title="Prefiks adresu URL obrazu"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <div class="form-group">
      <label>Prefiks adresu URL obrazu</label>
      <input v-model="tagSetup.imageUrlPrefix" @input="canReload = true" type="text" class="form-control"/>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      visibleModal: false,
      canReload: false,

      xmlUrl: null,
      tagSetup: null
    }
  },

  methods: {
    openModal(xmlUrl, tagSetup) {
      this.xmlUrl = xmlUrl
      this.tagSetup = tagSetup
      this.visibleModal = true
    },

    async hideModal() {
      if (this.canReload) {
        this.canReload = false
        await this.$root.$emit("ecat-parser-refresh-table", this.xmlUrl)
      }

      this.visibleModal = false
      this.tagSetup = null
    }
  }
}
</script>