import store from "@/state/store";

export const productHelper = {
    getAllegroSendStatus,
    checkAllegroSendStatusVariation
};

function getAllegroSendStatus(product) {
    if (product.allegroException) {
        return true;
    }

    if (product.allegroBlocked) {
        return false;
    }

    if (product.priceHigh >= store.getters['parameters/getAllegroSendMinPrice']) {
        return true
    }

    return this.checkAllegroSendStatusVariation(product);
}

function checkAllegroSendStatusVariation(product) {
    if (product.productAllegroException) {
        return true
    }

    if (product.productAllegroBlocked) {
        return false
    }

    return product.productPriceHigh >= store.getters['parameters/getAllegroSendMinPrice']
}