<template>
  <b-modal
      v-if="parserObject && tagSetup"
      v-model="visibleModal"
      title="Dopasowywanie atrybutów"
      title-class="font-18"
      hide-footer
      size="xl"
      centered
      @hide="hideModal"
      @esc="hideModal">
    <div class="row">
      <div class="col-6">
        <h2 class="text-center">Nazwy atrybutów</h2>

        <input v-model="filtering.attributeName" type="text" class="form-control" placeholder="Wyszukaj po nazwie Atrybutu" />

        <div class="card">
          <div class="card-body">
            <div class="rectangle-table">
              <div class="rectangle-content">
                <div v-for="(item, index) in filteredAttributes()" :key="index">
                  <parser-attribute-column
                      :parser-object="parserObject"
                      :tag-setup="tagSetup"
                      :attribute-name="item"
                      @match-attribute="attributeName => selectedAttribute = attributeName"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6">
        <h2 class="text-center">Tagi ECAT</h2>

        <input v-model="filtering.rightTagName" type="text" class="form-control" placeholder="Wyszukaj po nazwie TAGA" />

        <card>
            <div class="parser-rectangle-table">
              <div class="parser-rectangle-content">
                <parser-right-column
                    v-for="(tag, index) in filteredRightTags()"
                    :key="`parser-right-tag-${index}`"
                    :parser-object="parserObject"
                    :tag-setup="tagSetup"
                    :required-tags="requiredTags"
                    :matching-tag="matchingTag"
                    :tag="tag"
                    :visible-match-button="!!selectedAttribute"
                    @match-tag="matchRightTag"
                />

                <hr />
                <h2>Atrybuty:</h2>

                <parser-attribute
                    v-for="(attribute, index) in tagSetup.frontendAttributes"
                    :key="`parser-attribute-${index}`"
                    :parser-object="parserObject"
                    :tag-setup="tagSetup"
                    :matching-tag="matchingTag"
                    :attribute="attribute"
                    :visible-match-button="!!selectedAttribute"
                    @match-tag="matchRightTag"
                />

                <div class="button-items py-4 text-center">
                  <b-button variant="info" class="btn-lg" @click="$root.$emit('open-parser-add-attribute-modal', parserObject)">Dodaj nowy Atrybut</b-button>
                </div>
              </div>
            </div>
        </card>
    </div>
    </div>
  </b-modal>
</template>

<script>
import ParserRightColumn from "@/components/parser/parser-right-column.vue";
import {translateRightTagName} from "@/helpers/parser-util";
import ParserAttribute from "@/components/parser/parser-attribute.vue";
import ParserAttributeColumn from "@/components/parser/parser-attribute-column.vue";

export default {
  components: {ParserAttributeColumn, ParserAttribute, ParserRightColumn},

  props: {
    parserObject: {
      type: Object,
      required: true
    },
    
    tagSetup: {
      type: Object,
      required: true
    },

    requiredTags: {
      type: Array,
      required: true
    },

    matchingTag: {}
  },

  data() {
    return {
      filtering: {
        attributeName: "",
        rightTagName: ""
      },

      visibleModal: false,
      attributes: [],
      selectedAttribute: ""
    }
  },

  methods: {
    openModal(tag) {
      this.filtering.leftTagName = ""
      this.filtering.rightTagName = ""

      this.attributes = []
      this.selectedAttribute = ""
      for (const key in tag.attributes) {
        // eslint-disable-next-line no-prototype-builtins
        if (tag.attributes.hasOwnProperty(key)) {
          this.attributes.push(key)
        }
      }

      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
    },

    matchRightTag(rightTag, attribute) {
      this.$emit('match-right-tag', this.selectedAttribute, rightTag, attribute)
      this.selectedAttribute = ""
      this.hideModal()
    },

    filteredRightTags() {
      return this.filtering.rightTagName
          ? this.parserObject.rightTags.filter(element => translateRightTagName(element.name).toLowerCase().includes(this.filtering.rightTagName.toLowerCase()))
          : this.parserObject.rightTags;
    },

    filteredAttributes() {
      return this.filtering.attributeName
          ? this.attributes.filter(element => element.includes(this.filtering.attributeName))
          : this.attributes
    }
  }

}
</script>