<template>
  <b-modal
      v-model="visibleModal"
      size="xl"
      title="Filtrowanie po atrybutach"
      title-class="font-18"
      :scrollable="true"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">

    <div class="form-group">
      <div v-for="[key, value] in attributes" :key="key">
        <b>{{ key }}:</b>

        <div v-for="(item, index) in value.slice(0, getAttributesLength(key))" :key="index" style="margin-left: 5px;">
          <input
              :checked="attributeSelected(key, item)"
              @change="updateAttributeSelected(key, item)"
              type="radio"
              :id="`${key}-${item}`"
              :value="item"
              :name="`${key}-${item}`"
          />
          <label :for="item" style="margin-left: 5px;">{{ item }}</label>
        </div>

        <div class="form-group text-center">
          <b-button variant="primary" @click="loadMoreAttributes(key)" :disabled="getAttributesLength(key) >= value.length">Załaduj więcej</b-button>
        </div>
      </div>
    </div>

    <div class="form-group text-center py-2">
      <b-button variant="success" @click="acceptAttributes">Potwierdź</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  
  props: {
    parserObject: {
      type: Object,
      required: true
    },
    
    tagSetup: {
      type: Object,
      required: true
    }
  },
  
  data() {
    return {
      visibleModal: false,

      attribute: false,
      visible: false,
      selectedAttribute: "",
      tagPath: "",
      rightTag: null,
      attributes: [],
      attributesLength: new Map(),

      form: {
        selectedAttributes: null
      }
    }
  },

  computed: {
    attributeSelected() {
      return (group, value) => {
        return this.form.selectedAttributes.get(group) === value;
      };
    }
  },

  methods: {
    openModal(attribute, tag, rightTag, selectedAttribute = "") {
      this.rightTag = rightTag
      this.tagPath = tag.path
      this.attribute = attribute
      this.selectedAttribute = selectedAttribute

      this.form.selectedAttributes = new Map()

      const map = new Map();
      for (const key in tag.attributes) {
        // eslint-disable-next-line no-prototype-builtins
        if (tag.attributes.hasOwnProperty(key)) {
          map.set(key, tag.attributes[key]);
          this.form.selectedAttributes.set(key, "")
        }
      }

      this.attributes = map
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.attribute = false
      this.tagPath = ""
      this.rightTag = null
      this.attributes = null
      this.form.selectedAttributes = new Map()
    },

    acceptAttributes() {
      if (!this.tagSetup.matchedTags.find(element => {
        if (Array.from(this.form.selectedAttributes.keys()).length > 0) {
          return Array.from(this.form.selectedAttributes.entries())
                  .every(([key, value]) =>
                      element.tagAttributes
                      && key in element.tagAttributes
                      && element.tagAttributes[key] === value)
              && element.tagPath === this.tagPath;
        } else {
          return element.tagPath === this.tagPath;
        }
      })) {
        const tag = {
          name: this.rightTag.name,
          tagPath: this.tagPath,
          attribute: this.attribute,
          customTag: false
        }

        if (this.selectedAttribute) {
          tag["targetAttributeName"] = this.selectedAttribute
        }

        tag.tagAttributes = {}
        for (const [key, value] of this.form.selectedAttributes) {
          if (value) {
            tag.tagAttributes[key] = value;
          }
        }

        this.tagSetup.matchedTags.push(tag)
      }

      const matchedTag = this.tagSetup.matchedTags.find(element => {
        if (Array.from(this.form.selectedAttributes.keys()).length > 0) {
          return Array.from(this.form.selectedAttributes.entries())
                  .every(([key, value]) =>
                      element.tagAttributes
                      && key in element.tagAttributes
                      && element.tagAttributes[key] === value)
              && element.tagPath === this.tagPath;
        } else {
          return element.tagPath === this.tagPath;
        }
      });

      if (matchedTag) {
        matchedTag.tagAttributes = {}

        for (const [key, value] of this.form.selectedAttributes) {
          if (value) {
            matchedTag.tagAttributes[key] = value;
          }
        }
      }

      this.hideModal()

      const { parser } = this.parserObject
      this.$root.$emit("ecat-parser-refresh-table", parser.xmlUrl)
      this.$emit("reload-column")
    },

    updateAttributeSelected(group, value) {
      this.form.selectedAttributes.set(group, value)
    },

    getAttributesLength(attributeKey) {
      if (!this.attributesLength) {
        return 20
      }

      return this.attributesLength.get(attributeKey) || 20
    },

    loadMoreAttributes(attributeKey) {
      this.attributesLength.set(attributeKey, (this.attributesLength.get(attributeKey) || 20) + 20)
      this.$forceUpdate()
    }
  }
  
}
</script>