<template>
  <b-modal
      v-if="parserObject && tagSetup() && tagSetup().accessData"
      v-model="visibleModal"
      title="Dane dostępowe"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">

    <div class="form-group">
      <label>Nazwa Użytkownika</label>
      <input v-model="tagSetup().accessData.username" @change="canReload = true" type="text" class="form-control"/>
    </div>

    <div class="form-group">
      <label>Hasło</label>
      <input v-model="tagSetup().accessData.password"  @change="canReload = true"  type="text" class="form-control"/>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";

export default {

  data() {
    return {
      visibleModal: false,
      canReload: false,

      parserObject: null
    }
  },

  methods: {
    tagSetup() {
      return this.parserObject.tagSetup
    },

    openModal(parserObject) {
      this.parserObject = parserObject
      this.visibleModal = true
    },

    async hideModal() {
      if (this.canReload) {
        this.canReload = false

        const { parser } = this.parserObject
        await this.invalidateFileCache(parser)
        await this.updateParserTag(parser)
      }

      this.visibleModal = false
      this.parserObject = null
    },

    async invalidateFileCache(parser) {
      try {
        const json = {
          fileUrl: parser.xmlUrl
        }

        await axios.post(`/parser/tag/invalidate-file-cache`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async updateParserTag(parser) {
      const json = {
        xmlUrl: decodeURIComponent(parser.xmlUrl),
        tagSetup: JSON.stringify(this.tagSetup()),
        warehouseId: parser.warehouseId
      }

      try {
        await axios.post(`/parser/tag`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });
      } catch (error) {
        errorCatcher.catchErrors(error);
      }
    }
  }

}
</script>