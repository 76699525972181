import {translationHelper} from "@/helpers/translation-helper";

function translateRightTagName(tagName) {
    const key = `parser-tags.ecat-tag-names.${tagName}`
    const tagTranslation = translationHelper.t(key)
    if (tagTranslation && tagTranslation !== key) {
        return tagTranslation
    }

    return tagName
}

export { translateRightTagName }