<template>
  <rectangle :matched="!!matchedTag()" :required="requiredTags.includes(tag.name)" :key="`parser-right-column-${reloadColumn}`">
    <template #top-left-buttons>
      <b-button v-if="!!matchingTag && visibleMatchButton" @click="$emit('match-tag', tag, false)" variant="success" class="btn-sm"><i class="mdi mdi-plus" /></b-button>

      <i v-if="requiredTags.includes(tag.name)" class="text-danger" style="font-size: 32px;">*</i>
    </template>

    <template #centered>
      <h1 class="tag-name-clamp">{{ translateRightTagName(tag.name) }}</h1>
      <h1 v-if="!!matchedTag()" class="tag-name-clamp">(<b>{{ matchedTagName() }}</b>)</h1>
    </template>

    <template #buttons>
      <b-button v-if="!!matchedTag()" @click="$root.$emit('open-parser-tag-preview-modal', matchedLeftTag(matchedTag()))" variant="primary" class="btn-sm">Podgląd</b-button>
      <b-button v-if="!!matchedTag()" @click="$root.$emit('open-parser-reject-match-modal', parserObject.parser.xmlUrl, tagSetup, matchedLeftTag(matchedTag()), true)" variant="danger" class="btn-sm">Odrzuć dopasowania</b-button>
    </template>
  </rectangle>
</template>

<script>
import Rectangle from "@/components/rectangle.vue";
import {translateRightTagName} from "@/helpers/parser-util";

export default {
  components: {Rectangle},

  props: {
    requiredTags: {
      type: Array,
      required: true
    },

    matchingTag: {},

    parserObject: {
      type: Object,
      required: true
    },

    tagSetup: {
      type: Object,
      required: true
    },

    tag: {
      type: Object,
      required: true
    },

    visibleMatchButton: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      reloadColumn: false
    }
  },

  methods: {
    translateRightTagName,

    matchedTag() {
      return this.tagSetup.matchedTags.find(item => item.name === this.tag.name);
    },

    matchedLeftTag(matchedTag) {
      return matchedTag ? this.parserObject.leftTags.find(item => item.path === matchedTag.tagPath) : null;
    },

    matchedTagName() {
      const matchedTag = this.matchedTag()
      const matchedLeftTag = this.matchedLeftTag(matchedTag)
      if (!matchedLeftTag) {
        return null
      }

      return matchedTag.targetAttributeName
          ? `${matchedLeftTag.tagName}/${matchedTag.targetAttributeName}`
          : matchedLeftTag.tagName
    }
  },

  mounted() {
    this.$root.$on("reload-parser-columns", () => {
      this.reloadColumn = !this.reloadColumn
    })
  },

  beforeDestroy() {
    this.$root.$off("reload-parser-columns");
  }

}
</script>