export const formatHelper = {
    format
};

function format(amount, first, second, third) {
    if (amount === 1) {
        return first;
    } else if (amount >= 2 && amount < 5) {
        return second;
    }

    return third;
}