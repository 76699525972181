<template>
  <b-modal
      size="lg"
      :scrollable="true"
      v-model="visibleModal"
      v-if="visibleModal"
      :title="$t('products.details.title')"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <form>

      <div class="table-responsive py-3">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <th>{{ $t('products.details.table.category-path') }}</th>
            <td>{{ product.category && product.category.path ? product.category.path : '-' }}</td>
          </tr>

          <tr>
            <th>Łączna ilość sztuk</th>
            <td>{{ product.totalWarehouseStock }}</td>
          </tr>

          <tr>
            <th>{{ $t('products.details.table.price-range') }}</th>
            <td>{{ priceHelper.format(product.minMargin, $store.getters['market/currentCurrency']) }} {{ product.minMargin !== product.maxMargin ? '- ' + priceHelper.format(product.maxMargin, $store.getters['market/currentCurrency']) : '' }}</td>
          </tr>

          <tr>
            <th>{{ $t('products.details.table.delivery-cost') }}</th>
            <td>
              <template v-if="getShippingCost(product.id)">
                <p v-for="(shippingCostItem, index) in getShippingCost(product.id)" :key="index">{{shippingCostItem.name}}: {{ priceHelper.format(shippingCostItem.cost, $store.getters['market/currentCurrency']) }}</p>
              </template>
              <template v-else>
                -
              </template>
            </td>
          </tr>

          </tbody>
        </table>
      </div>

      <div class="py-3">
        <h5>{{ $t('products.details.table.variations') }}</h5>

        <div class="py-3" v-if="getProductVariations(product)">
          <div v-for="(variation, index) in getProductVariations(product)" :key="index">
            <p>{{ $t('products.variations.details') }}</p>

            <div class="table-responsive py-3">
              <table class="table table-bordered">
                <tbody>
                <tr>
                  <th>{{ $t('products.details.table.product-name') }}</th>
                  <td>{{ variation.name }}</td>
                </tr>
                <tr>
                  <th>
                    Atrybuty
                  </th>
                  <td>
                    <table>
                      <tbody>
                      <tr v-for="(attribute, index) in getProductAttributes(variation.id)" :key="index" style="border: none !important; padding: 0; height: 20px !important;">
                        <td style="border: none !important; padding: 0 !important;">{{ attribute }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <th>Ilość sztuk</th>
                  <td>{{ variation.availableIn }}</td>
                </tr>

                <tr>
                  <th>SKU</th>
                  <td>{{ variation.sku ? 'W' + variation.sku : "-" }}</td>
                </tr>

                <tr>
                  <th>EAN</th>
                  <td>{{
                      variation.ean && jsonUtil.asArray(variation.ean).length > 0 ? jsonUtil.asArray(variation.ean)[0] : "-"
                    }}
                  </td>
                </tr>

                <tr>
                  <th>Cena sklepu brutto</th>
                  <td>{{
                      priceHelper.format(variation.priceMarginTaxIncluded, $store.getters["market/currentCurrency"])
                    }}
                  </td>
                </tr>

                <tr>
                  <th>Cena hurtowni netto</th>
                  <td>{{ priceHelper.format(variation.price, $store.getters["market/currentCurrency"]) }}</td>
                </tr>

                <tr>
                  <th>{{ $t('products.details.table.description') }}</th>
                  <td>
                    <div v-html="variation.description"></div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="py-3">
              <h5>{{ $t('products.details.table.pictures') }}</h5>

              <template v-if="variation.images">
                <template v-if="jsonUtil.asArray(variation.images).length > 0">
                  <img class="img-fluid mr-2" v-for="(image, index) in jsonUtil.asArray(variation.images)" :key="index" :src="image" alt width="120" @click="$emit('show-product-images', jsonUtil.asArray(variation.images), index)"/>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {priceHelper} from "@/helpers/price-helper";
import {jsonUtil} from "@/helpers/json-util";

export default {

  props: {
    shippingCosts: {
      type: Map,
      required: true
    },

    variations: {
      type: Map,
      required: true
    },

    attributes: {
      type: Map,
      required: true
    }
  },

  data() {
    return {
      visibleModal: false,
      product: null
    }
  },
  
  computed: {
    priceHelper() {
      return priceHelper
    },
    
    jsonUtil() {
      return jsonUtil
    }
  },
  
  methods: {
    openModal(product) {
      this.product = product
      this.visibleModal = true
    },
    
    hideModal() {
      this.visibleModal = false
      this.product = null
    },

    getShippingCost(productId) {
      if (!this.shippingCosts || this.shippingCosts.size === 0) {
        return null;
      }

      return this.shippingCosts.get(productId) || null;
    },

    getProductVariations(product) {
      return this.variations ? this.variations.get(product.warehouseProduct) || null : null;
    },

    getProductAttributes(productVariationId) {
      return this.attributes ? this.attributes.get(productVariationId) || null : null;
    }
  }
  
}
</script>