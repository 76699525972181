<template>
  <b-modal
      id="modal-1"
      v-model="visibleModal"
      title="Usuwanie parsera"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <div class="form-group text-center">
      <p>W celu potwierdzenia zmian przepisz poniższy kod:</p>
      <p><b>{{ code }}</b></p>
      <input v-model="form.inputCode" type="text" class="form-control" />
    </div>

    <div class="text-right">
      <b-button @click="deleteParsers" variant="danger">Usuń parser</b-button>
      <b-button class="ml-1" variant="secondary" @click="hideModal">{{  $t('message.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";
import {codeGenerator} from "@/helpers/code-generator";

export default {
  name: "delete-parser-modal",

  data() {
    return {
      visibleModal: false,
      warehouseId: "",
      code: "",
      form: {
        inputCode: ""
      }
    }
  },

  methods: {
    openModal(warehouseId) {
      this.code = codeGenerator.generateRandomCode(6)
      this.warehouseId = warehouseId
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.form.inputCode = ""
    },

    async deleteParsers() {
      if (this.code !== this.form.inputCode) {
        await Swal.fire("Błąd!", "Kod przepisany nie zgadza się!", "error");
        return
      }

      const parsers = await this.loadParsers()
      for (const parser of parsers) {
        if (parser.id) {
          await this.deleteParser(parser)
        }
      }

      const parserTags = await this.loadParsersTags()
      for (const parserTag of parserTags) {
        await this.deleteParserTag(parserTag.xmlUrl, parserTag.warehouseId)
      }

      await Swal.fire("Sukces!", "Parser został usunięty!", "success")
          .then(() => {
            if (this.$route.path.includes("/dashboard/admin/parser/create-or-edit")) {
              window.location = "/dashboard/admin/parser/create-or-edit"
              return
            }

            setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
          });
    },

    async loadParsers() {
      try {
        const { data } = await axios.get(`/parser/list/by/warehouse/${this.warehouseId}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        return data
      } catch (error) {
        errorCatcher.catchErrors(error)
        return null
      }
    },

    async loadParsersTags() {
      try {
        const { data } = await axios.get(`/parser/tag/list/by/warehouse/${this.warehouseId}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        return data
      } catch (error) {
        errorCatcher.catchErrors(error)
        return null
      }
    },


    async deleteParser(parser) {
      if (!parser.id) {
        return
      }

      try {
        await axios.delete(`/parser/${parser.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async deleteParserTag(xmlUrl, warehouseId) {
      try {
        const json = JSON.stringify({
          "xmlUrl": xmlUrl,
          "warehouseId": warehouseId
        })

        await axios.post(`/parser/tag/delete`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }

  }

}
</script>