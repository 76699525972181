<template>
  <div>
    <parser-add-relation-ship-modal ref="parserAddRelationShipModal" @can-reload="canReload = true" />

    <b-modal
        v-if="parserObject && tagSetup()"
        v-model="visibleModal"
        hide-footer
        size="xl"
        title="Relacje"
        title-class="font-18"
        @esc="hideModal"
        @hide="hideModal">
      <div class="form-group">
        <div class="row">
          <div class="col-12">
            <card>
              <div class="parser-rectangle-table">
                <div class="parser-rectangle-content">
                  <div v-for="(relationShip, index) in tagSetup().relationships" :key="index">
                    <parser-relation-column
                        :connected-name="connectedName(relationShip)"
                        :tag-name="relationShip.name"
                        @edit="editRelationShipModal(relationShip)"
                        @remove="removeRelationShip(relationShip)"
                    />
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>

      <div class="form-group text-center">
        <b-button variant="success" @click="openAddRelationShipModal">Dodaj relacje</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ParserRelationColumn from "@/components/parser/parser-relation-column.vue";
import parserAddRelationShipModal from "@/components/parser/parser-add-relation-ship-modal.vue";

export default {
  components: {parserAddRelationShipModal, ParserRelationColumn},
  data() {
    return {
      visibleModal: false,
      canReload: false,

      parserObject: null
    }
  },

  methods: {
    tagSetup() {
      return this.parserObject.tagSetup
    },

    openModal(parserObject) {
      this.parserObject = parserObject
      this.visibleModal = true
    },

    async hideModal() {
      this.visibleModal = false

      if (this.canReload) {
        const { parser } = this.parserObject
        await this.$root.$emit("ecat-parser-refresh-table", parser.xmlUrl)
        this.canReload = false
      }
    },

    openAddRelationShipModal() {
      this.$refs.parserAddRelationShipModal.openModal(this.parserObject)
    },

    editRelationShipModal(relationShip) {
      this.$refs.parserAddRelationShipModal.openEditingModal(this.parserObject, relationShip)
    },

    removeRelationShip(relationShip) {
      this.tagSetup().relationships = this.tagSetup().relationships.filter(element => element.name !== relationShip.name)
    },

    connectedName(relationship) {
      let text = this.getLastPart(relationship.keyTag.path)
      if (relationship.keyTag.targetAttributeName) {
        text += "/" + relationship.keyTag.targetAttributeName
      }

      text += " -> "
      text += this.getLastPart(relationship.valueTag.path)

      if (relationship.valueTag.targetAttributeName) {
        text += "/" + relationship.valueTag.targetAttributeName
      }

      return text
    },

    getLastPart(text) {
      return text.includes('.') ? text.split('.').pop() : text;
    }

  }

}
</script>